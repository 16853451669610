import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import CourseDetails from "../components/CourseDetails";

const CourseDetailsTemplate = ({ data }) => {
  return (
    <CourseDetails
      data={data}
    />
  );
};

const BasicCourseDetails = ({ data }) => {
  const { markdownRemark } = data;
  
  return (
    <Layout>
      <CourseDetailsTemplate
        data={markdownRemark}
        helmet={
          <Helmet titleTemplate="%s | Course Details - Apex Learning Institute">
            <title>{`${markdownRemark.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${markdownRemark.frontmatter.description}`}
            />
          </Helmet>
        }
      />
    </Layout>
  );
};

BasicCourseDetails.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export const pageQuery = graphql`
  query Course($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        name
        desc
        image {
          childImageSharp {
            gatsbyImageData(quality: 100)
          }
        }
        videoLinks {
          title
          link
        }
      }
    }
  }
`;

export default BasicCourseDetails;
